import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { CitrusLogo } from "./Logo/Logo";
import { Button, Divider } from "@mui/material";
import styled from "@emotion/styled";

const BackgroundWrapper = styled.div`
  background-image: url("/logo.png");
  background-repeat: repeat;
  background-size: 100px 100px;
  background-position: 0 0, 25px 25px;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  padding: 20px 0;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    z-index: 0;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export default function App() {
  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Container
          maxWidth="sm"
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
            border: "3px solid #ffff00",
          }}
        >
          <Box
            sx={{
              pt: 3,

              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: 2,
            }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {/* <Typography
              variant="h4"
              component="h1"
              sx={{
                mb: 2,
                fontFamily: "'Pacifico', cursive",
                fontSize: "2.5rem",
                color: "grey",
              }}
            >
              Citrus
            </Typography> */}

            {/* <Typography
              variant="body1"
              component="p"
              textAlign={"center"}
              sx={{ fontSize: "30px" }}
            >
              🍋🍋🍋
            </Typography> */}
            <Typography
              variant="body1"
              component="p"
              textAlign={"center"}
              fontSize={28}
              sx={{ fontStyle: "italic" }}
            >
              Reply to your emails with Citrus. Divide the time spent in your
              inbox by three.
            </Typography>
            {/* <Typography
              variant="body1"
              component="p"
              textAlign={"center"}
              sx={{ fontSize: "30px", transform: "scaleX(-1)" }}
            >
              🍋🍋🍋
            </Typography> */}

            {/* <CitrusLogo width={100} height={100} /> */}
          </Box>
          <Divider sx={{ width: "100%", my: 2 }} />

          <Box display="flex" gap={2} justifyContent="center">
            <Button>Connect</Button>
            <Button variant="contained">Try it out</Button>
          </Box>
          <Divider sx={{ width: "100%", my: 2 }} />

          <Box
            sx={{
              my: 4,
              backgroundColor: "rgba(255, 255, 255, 1)",
              padding: 3,
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: 0,
                paddingBottom: "56.25%", // 16:9 aspect ratio
                position: "relative",
                backgroundColor: "#f0f0f0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            ></Box>
            <Typography variant="body2" sx={{ mb: 2, textAlign: "center" }}>
              Watch our demo video to see Citrus in action
            </Typography>
          </Box>
          <Box
            sx={{
              my: 4,
              backgroundColor: "rgba(255, 255, 255, 1)",
              padding: 3,
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{ mb: 2 }}
              textAlign={"center"}
            >
              FAQ
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              sx={{ mb: 1, fontWeight: "bold" }}
            >
              Can I use my own email address? Like gmail, outlook, etc.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Yes, you can use your own email address. We support Gmail,
              Outlook, and other email providers.
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              sx={{ mb: 1, fontWeight: "bold" }}
            >
              How does it work?
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Citrus crafts your emails for you. It uses AI to generate emails
              based on your own filters, previous messages and the context your
              provide.
            </Typography>
          </Box>
        </Container>
      </ContentWrapper>
    </BackgroundWrapper>
  );
}
